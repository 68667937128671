<template>
  <footer>
    <div class="container">
      <div class="footer_top">
        <div class="footer_top__column footer_top__column--about">
          <div class="footer_top__title">
            <router-link to="/aboutus/index.html" @click="scrollToTop()">About Us</router-link>
          </div>
        </div>
        <div class="footer_top__column footer_top__column--contact">
          <div class="footer_top__title">
            <router-link to="/contactus/index.html" @click="scrollToTop()">Contact Us</router-link>
          </div>
        </div>
        <div class="footer_top__column footer_top__column--locate">
          <div class="footer_top__title">
            <router-link to="/locate_us/index.html" @click="scrollToTop()">Locate Us</router-link>
          </div>
        </div>
        <div class="footer_top__column footer_top__column--blog">
          <div class="footer_top__title">
            <a href="https://blog.applecrumbyandfish.com/" target="_blank">Blog</a>
          </div>
        </div>
        <div class="footer_top__column footer_top__column--regional_partners">
          <div class="footer_top__title">
            <a target="_blank" href="https://www.applecrumbyandfish.com/locate_us/index.html#regional_partners">Regional Partners</a>
          </div>
          <div class="footer_top__links-regional_partners">
            <ul>
              <li>
                <a href="/regional_partners/thailand/index.html" target="_blank">Thailand</a>
              </li>
              <li>
                <a href="/regional_partners/philippines/index.html" target="_blank">Philippines</a>
              </li>
              <li>
                <a href="/regional_partners/singapore/index.html" target="_blank">Singapore</a>
              </li>
              <li>
                <a target="_blank">Malaysia</a>
              </li>
              <li>
                <a href="/regional_partners/korea/index.html" target="_blank">Korea</a>
              </li>
              <li>
                <a href="/regional_partners/vietnam/index.html" target="_blank">Vietnam</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer_top__column footer_top__column--faq">
          <div class="footer_top__title">
            <router-link @click="scrollToTop()" to="/faq/index.html">FAQ</router-link>
          </div>
          <div class="footer_top__links">
            <ul>
              <li>
                <router-link @click="scrollToTop()" :to="{name: 'FAQ', hash: '#delivery'}">Delivery</router-link>
              </li>
              <li>
                <router-link @click="scrollToTop()" :to="{name: 'FAQ', hash: '#returns'}">Returns</router-link>
              </li>
              <li>
                <router-link @click="scrollToTop()" :to="{name: 'FAQ', hash: '#orders'}">Payment</router-link>
              </li>
              <li>
                <router-link @click="scrollToTop()" to="/reward-policy/index.html">Rewards</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="footer_top__column footer_top__column--follow">
          <div class="footer_top__title">Follow Us</div>
          <div class="footer_top__icons">
            <ul>
              <li>
                <a href="https://www.facebook.com/applecrumbyandfish" target="_blank" aria-label="Facebook" ><img alt="facebook" src="../assets/images/landingpage/facebook.svg" /></a>
              </li>
              <li>
                <a href="https://www.instagram.com/applecrumby.official" target="_blank" aria-label="Instagram" ><img alt="instagram" src="../assets/images/landingpage/instagram.svg" /></a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@applecrumby.official" target="_blank" aria-label="TikTok" ><img alt="tiktok" src="../assets/images/landingpage/tiktok.svg" /></a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCkUvxNwn4OE2fV729OPCsdQ" target="_blank" aria-label="YouTube" ><img alt="youtube" src="../assets/images/landingpage/youtube.svg" /></a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="this.$route.name != 'LocateUs' && this.$route.name != 'Locate_Us'" class="footer_top__column footer_top__column--top">
          <div class="pagetop" @click="scrollToTop()">
            <img alt="back to top" src="../assets/images/landingpage/back-to-top-button.svg" />
            <p>Back to top</p>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <div class="footer_bottom__right">
          <ul>
            <li><router-link @click="scrollToTop()" to="/reward-policy/index.html">Reward Policy</router-link></li>
            <li><router-link @click="scrollToTop()" :to="{name: 'FAQ', hash: '#returns'}">Return Policy</router-link></li>
            <li>
              <router-link @click="scrollToTop()" to="/policy/index.html">Privacy Policy</router-link>
            </li>
            <li>
              <router-link @click="scrollToTop()" to="/termsconditions/index.html">Terms & Conditions</router-link>
            </li>
          </ul>
        </div>
        <div class="footer_bottom__left">
          <p>All Rights Reserved 2023. Conceived in Oct 2012 by Sean & Jesmine. <br> Bunny & The Duchess Sdn Bhd (989395-D)</p>
          <p></p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "BackToTop",
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};

window.zEmbed ||
  (function (e, t) {
    var n,
      o,
      d,
      i,
      s,
      a = [],
      r = document.createElement("iframe");
    (window.zEmbed = function () {
      a.push(arguments);
    }),
      (window.zE = window.zE || window.zEmbed),
      (r.src = "javascript:false"),
      (r.title = ""),
      (r.role = "presentation"),
      ((r.frameElement || r).style.cssText = "display: none"),
      (d = document.getElementsByTagName("script")),
      (d = d[d.length - 1]),
      d.parentNode.insertBefore(r, d),
      (i = r.contentWindow),
      (s = i.document);
    try {
      o = s;
    } catch (e) {
      (n = document.domain), (r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);'), (o = s);
    }
    (o.open()._l = function () {
      var o = this.createElement("script");
      n && (this.domain = n), (o.id = "js-iframe-async"), (o.src = e), (this.t = +new Date()), (this.zendeskHost = t), (this.zEQueue = a), this.body.appendChild(o);
    }),
      o.write('<body onload="document._l();">'),
      o.close();
  })("//assets.zendesk.com/embeddable_framework/main.js", "applecrumbyandfish.zendesk.com");
</script>
