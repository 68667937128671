export default {
    isLoading: true,
    access_token: null,
    user: null,
    isLoggedIn: false,
    categories: [],
    carts: {
        items: [],
        point_earned: 0,
        promocodes: [],
        shipping: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        display_shipping_fee: 'RM 0.00',
        display_subtotal: 'RM 0.00',
        display_tax: 'RM 0.00',
        display_total: 'RM 0.00',
    },
    customer_groups: [],
    variant_types: {
        color: 'Color',
        size: 'Size',
    },
    countries: [],
    states: [],
    payment_methods: [],
    temp_order_data: {},
    brand_id: {
        1 : 'Applecrumby®',
        2 : 'PureBasics™️'
    }
};
