<template>
  <Header_info :key="$route.fullPath" v-if="isLoaded && this.$store.state.server_status != 'maintenance' && this.$route.path != '/affiliates_agreement_form/index.html'"></Header_info>
  <router-view :key="$route.fullPath" v-if="isLoaded"></router-view>
  <Footer_info v-if="isLoaded && this.$store.state.server_status != 'maintenance' && this.$route.path != '/affiliates_agreement_form/index.html'" ></Footer_info>
  <Loader_component v-if="!isLoaded" :loading="true" />
</template>

<script>
import Footer_info from "@/include/footer_info.vue";
import Header_info from "@/include/header_info.vue";
import Loader_component from "@/include/loader_component.vue";
import globals from "@/config/globals.js";
import { getGuestID, getRAPI, getAPI } from "@/modules/general.js";
import { verifyLoginAPI } from "./modules/auth";

export default {
  data() {
    return {
      isLoaded: false,
    };
  },
  components: {
    Footer_info,
    Header_info,
    Loader_component,
  },
  async created() {
    
    const generalLibResponse = await fetch('https://static.applecrumbyandfish.com/lib/general.json');
    const generalDataResponse = await generalLibResponse.json();

    if (generalDataResponse.code == "success") {
      globals.categories = generalDataResponse.data.categories ? generalDataResponse.data.categories : [];
      globals.customer_groups = generalDataResponse.data.customer_groups ? generalDataResponse.data.customer_groups : [];
      globals.variant_types = generalDataResponse.data.variantTypes ? generalDataResponse.data.variantTypes : globals.variantTypes;
      globals.payment_methods = generalDataResponse.data.payment_methods ? generalDataResponse.data.payment_methods : globals.payment_methods;

      this.$store.commit('toggleServer', generalDataResponse.data.status);
      if (this.$store.state.server_status == 'maintenance') {
        this.$router.push({ name: 'MaintenancePage' })
      }
      const localisationLibResponse = await fetch('https://static.applecrumbyandfish.com/lib/localisation.json');
      const generalDataLocalisationResponse = await localisationLibResponse.json();
      if (generalDataLocalisationResponse.code == "success") {
        globals.states = generalDataLocalisationResponse.data.states ? generalDataLocalisationResponse.data.states : [];
        globals.countries = generalDataLocalisationResponse.data.countries ? generalDataLocalisationResponse.data.countries : [];
        localStorage.states = JSON.stringify(globals.states);
        localStorage.countries = JSON.stringify(globals.states);
      }
    }
    if (!globals.isLoggedIn && !globals.access_token) {
      this.$store.state.is_new = 1;
    }
    globals.access_token = localStorage.access_token ? localStorage.access_token : "";
    if (globals.access_token.trim()) {
      var verifyLoginResponse = await verifyLoginAPI();
      if (verifyLoginResponse.code == "success") {
        globals.isLoggedIn = true;
        globals.user = verifyLoginResponse.data;
      } else {
        globals.isLoggedIn = false;
        globals.access_token = null;
        localStorage.access_token = "";
      }
    }

    if (globals.isLoggedIn == true) {
      var UserPointResponse = await getAPI("/user/point");
      if (UserPointResponse.code == "success") {
        this.$store.state.user_points = UserPointResponse.data;
      }
    }

    var cartUser = {}
    if (!globals.isLoggedIn) {
      if (!localStorage.guest_id) {
        this.$store.state.is_new = 1;
      }

      cartUser['guest_id'] = getGuestID();
    }
    var getCartCountResponse = await getAPI("/cart/count", cartUser)
    if (getCartCountResponse.code == "success") {
      this.$store.state.badgeCount = getCartCountResponse.data.count;
    }

    var CollectionsResponse = await getRAPI("/collection");
    if (CollectionsResponse.code == "success") {
      this.$store.state.site_collection = CollectionsResponse.data;
    }

    var PickupResponse = await getRAPI("/pickupdeal");
    if (PickupResponse.code == "success") {
      this.$store.state.site_pickup_collection = PickupResponse.data;
    }

    this.isLoaded = true;
  },
};
</script>
<style lang="scss">
@import "./css/scss/style.scss";
</style>
