import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

import routeExternalProduct from "../cloud/categories.json";
import routeExternalProductContent from "../cloud/products.json";
import routeSales from "../cloud/collection.json";
import routePickupSales from "../cloud/pickup_collection.json";



let routeExternalData = [];
for (var i = 0; i < routeExternalProduct.length; i++) {
  var slug = routeExternalProduct[i].slug.toLowerCase();
  routeExternalData.push({
    path: "/" + slug,
    name: 'Category -' + routeExternalProduct[i].name,
    component: () => import("../views/ProductListing.vue"),
    meta: {
      title: "Applecrumby® - " + routeExternalProduct[i].name + " - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      params: {
        category: slug,
      },
      metaTags: [
        { name: "description", content: routeExternalProduct[i].meta_og_description },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" + slug },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" + slug },
        { name: "keywords", content: routeExternalProduct[i].meta_description },
        { name: "twitter:image", content: routeExternalProduct[i].image },
        { name: "twitter:description", content: routeExternalProduct[i].meta_og_description },
        { name: "twitter:card", content: "Category" },
        { itemprop: "name", content: routeExternalProduct[i].name },
        { property: "og:title", content: routeExternalProduct[i].meta_title },
        { property: "og:description", content: routeExternalProduct[i].meta_og_description },
        { property: "og:image", content: routeExternalProduct[i].image },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" + slug },

      ],
    },
    children: [
      {
        path: "/" + slug + "/index.html",
        name: 'CategoryPage -' + routeExternalProduct[i].name,
      }
    ]
  });
}

for (var c = 0; c < routeExternalProductContent.length; c++) {
  routeExternalData.push({
    path: "/product/" + routeExternalProductContent[c].slug,
    name: 'Product -' + routeExternalProductContent[c].name,
    component: () => import("../views/ProductInner.vue"),
    meta: {
      title: routeExternalProductContent[c].name,
      params: {
        slug: routeExternalProductContent[c].slug,
      },
      metaTags: [
        { name: "description", content: routeExternalProductContent[c].meta_og_description },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/product/" + routeExternalProductContent[c].slug },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/product/" + routeExternalProductContent[c].slug },
        { name: "keywords", content: routeExternalProductContent[c].meta_description },
        { name: "twitter:image", content: routeExternalProductContent[c].image },
        { name: "twitter:description", content: routeExternalProductContent[c].meta_og_description },
        { name: "twitter:card", content: "Product" },
        { itemprop: "name", content: routeExternalProductContent[c].name },
        { property: "og:title", content: routeExternalProductContent[c].meta_title },
        { property: "og:description", content: routeExternalProductContent[c].meta_og_description },
        { property: "og:image", content: routeExternalProductContent[c].image },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/product/" + routeExternalProductContent[c].slug },
      ],
    },  
    children: [
      {
        path: "/product/" + routeExternalProductContent[c].slug + "/index.html",
        name: 'ProductPage -' + routeExternalProductContent[c].name,
      }
    ]
  });
}

for (var s = 0; s < routeSales.length; s++) {
  routeExternalData.push({
    path: "/sales/" + routeSales[s].slug,
    name: 'Sale - ' + routeSales[s].name,
    component: () => import("../views/SalesProduct.vue"),
    meta: {
      title: "Applecrumby® -" + routeSales[s].name + "- Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      params: {
        slug: routeSales[s].slug,
      },
      metaTags: [
        { name: "description", content: routeSales[s].meta_og_description },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/sales/" + routeSales[s].slug },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/sales/" + routeSales[s].slug },
        { name: "keywords", content: routeSales[s].meta_description },
        { name: "twitter:image", content: routeSales[s].image },
        { name: "twitter:description", content: routeSales[s].meta_og_description },
        { name: "twitter:card", content: "Sales" },
        { itemprop: "name", content: routeSales[s].name },
        { property: "og:title", content: routeSales[s].meta_title },
        { property: "og:description", content: routeSales[s].meta_og_description },
        { property: "og:image", content: routeSales[s].image },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/sales/" + routeSales[s].slug },

      ],
    },  
    children: [
      {
        path: "/sales/" + routeSales[s].slug + "/index.html",
        name: 'SalePage - ' + routeSales[s].name,
      }
    ]
  });
}

for (var psc = 0; psc < routePickupSales.length; psc++) {
  routeExternalData.push({
    path: "/pickup_deal/" + routePickupSales[psc].slug,
    name: 'Pick-Up Deal - ' + routePickupSales[psc].title,
    component: () => import("../views/PickCollection/SalesPickup.vue"),
    meta: {
      title: "Applecrumby® - " + routePickupSales[psc].title + "- Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      params: {
        slug: routePickupSales[psc].slug,
      },
      metaTags: [
        { name: "description", content: routePickupSales[psc].description },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/pickup_deal/" + routePickupSales[psc].slug },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/pickup_deal/" + routePickupSales[psc].slug },
        { name: "keywords", content: routePickupSales[psc].description },
        { name: "twitter:image", content: routePickupSales[psc].image },
        { name: "twitter:description", content: routePickupSales[psc].description },
        { name: "twitter:card", content: "Pick-Up Deal" },
        { itemprop: "name", content: routePickupSales[psc].title },
        { property: "og:title", content: routePickupSales[psc].title },
        { property: "og:description", content: routePickupSales[psc].description },
        { property: "og:image", content: routePickupSales[psc].image },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/pickup_deal/" + routePickupSales[psc].slug },

      ],
    },
    children: [
      {
        path: "/pickup_deal/" + routePickupSales[psc].slug + "/index.html",
        name: 'Pick-Up_Deal - ' + routePickupSales[psc].title,
      }
    ]
  });
}

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "Applecrumby® - HomePage - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - HomePage - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - HomePage - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
  },
  {
    path: "/:categories",
    name: "ProductCategoryPage",
    component: () => import("../views/ProductListing.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      params: {
        category: "",
      },
      metaTags: [
        { name: "description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/:categories" + "/index.html",
        name: "ProductCategory",
      }
    ]
  },
  {
    path: "/pickup_deal/:slug",
    name: "Pick-Up_Deal",
    component: () => import("../views/PickCollection/SalesPickup.vue"),
    meta: {
      title: "Applecrumby® - Pick-Up Deal -  Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Pick-Up Deal Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Pick-Up Deal - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Pick-Up Deal - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },
      ],
    },
    children: [
      {
        path: "/pickup_deal/:slug" + "/index.html",
        name: "Pick-Up Deal",
      }
    ]
  },
  {
    path: "/category/all",
    name: "Product_Category_All",
    component: () => import("../views/ProductAllListing.vue"),
    meta: {
      title: "Applecrumby® - Product Category All -  Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "All Category Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Product Category All - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Product Category All - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },
      ],
    },
    children: [
      {
        path: "/category/all/index.html",
        name: "ProductCategoryAll",
      }
    ]
  },
  {
    path: "/product/:slug",
    name: "ProductPage",
    component: () => import("../views/ProductInner.vue"),
    meta: {
      title: "Applecrumby® - Product -  Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      params: {
        slug: "",
      },
      metaTags: [
        { name: "description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/product/:slug" + "/index.html",
        name: "Product",
      }
    ]
  },
  {
    path: "/checkout/cart",
    name: "Checkout_Cart",
    component: () => import("../views/CheckoutCart.vue"),
    meta: {
      title: "Applecrumby® - Checkout Cart - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Checkout Cart Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Cart - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Cart - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/checkout/cart/index.html",
        name: "CheckoutCart",
      }
    ]
  },
  {
    path: "/checkout/detail",
    name: "Checkout_Detail",
    component: () => import("../views/CheckoutDetail.vue"),
    meta: {
      title: "Applecrumby® - Checkout Detail - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Checkout Detail Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Checkout Detail - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Checkout Detail - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/checkout/detail/index.html",
        name: "CheckoutDetail",
      }
    ]
  },
  {
    path: "/checkout/confirmation",
    name: "Checkout_Confirmation",
    component: () => import("../views/CheckoutConfirmation.vue"),
    meta: {
      title: "Applecrumby® - Checkout Confirmation - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Checkout Confirmation Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Checkout Confirmation - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Checkout Confirmation - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/checkout/confirmation/index.html",
        name: "CheckoutConfirmation",
      }
    ]
  },
  {
    path: "/checkout/success",
    name: "Checkout_Success",
    component: () => import("../views/CheckoutSuccess.vue"),
    meta: {
      title: "Applecrumby® - Checkout Success - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Checkout Success Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Checkout Success - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Checkout Success - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/checkout/success/index.html",
        name: "CheckoutSuccess",
      }
    ]
  },
  {
    path: "/checkout/pending",
    name: "Checkout_Pending",
    component: () => import("../views/CheckoutPending.vue"),
    meta: {
      title: "Applecrumby® - Checkout Pending - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Checkout Pending Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Checkout Pending - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Checkout Pending - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/checkout/pending/index.html",
        name: "CheckoutPending",
      }
    ]
  },
  {
    path: "/checkout/pending/submit_success",
    name: "Checkout_Pending_Submit_Success",
    component: () => import("../views/CheckoutPendingSubmitSuccess.vue"),
    meta: {
      title: "Applecrumby® - Checkout Pending Submit Success - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Checkout Pending Submit Success Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Checkout Pending Submit Success - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Checkout Pending Submit Success - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/checkout/pending/submit_success/index.html",
        name: "CheckoutPendingSubmitSuccess",
      }
    ]
  },
  {
    path: "/maintenance",
    name: "Maintenance_Page",
    component: () => import("../views/MaintenancePage.vue"),
    meta: {
      title: "Applecrumby® is under Maintenance",
      metaTags: [
        { name: "description", content: "Maintenance Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® is under Maintenance" },
        { itemprop: "name", content: "Applecrumby® is under Maintenance" },
        { property: "og:title", content: "Applecrumby® is under Maintenance" },
        { property: "og:description", content: "Applecrumby® is under Maintenance" },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/maintenance/index.html",
        name: "MaintenancePage",
      }
    ]
  },
  {
    path: "/checkout/failed",
    name: "Checkout_Failed",
    component: () => import("../views/CheckoutFail.vue"),
    meta: {
      title: "Applecrumby® - Checkout Failed - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Checkout Fail Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Checkout Failed - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Checkout Failed - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/checkout/failed/index.html",
        name: "CheckoutFailed",
      }
    ]
  },
  {
    path: "/aboutus",
    name: "About_Us",
    component: () => import("../views/AboutUs.vue"),
    meta: {
      title: "Applecrumby® - About Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "About Us" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - About Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - About Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/aboutus/index.html',
        name: "AboutUs",
      }
    ]
  },
  {
    path: "/login",
    name: "Log_in",
    component: () => import("../views/LoginPage.vue"),
    meta: {
      title: "Applecrumby® - Login - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Login Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Login - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Login - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/login/index.html',
        name: "Login",
      }
    ]
  },
  {
    path: "/signup",
    name: "Sign_Up",
    component: () => import("../views/SignupPage.vue"),
    meta: {
      title: "Applecrumby® - Sign Up - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Signup Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Sign Up - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Sign Up - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/signup/index.html",
        name: "SignUp",
      },
      {
        path: "/register",
        name: "Register",
      },
    ]
  },
  {
    path: "/flash_deals",
    name: "Flash_Deals",
    component: () => import("../views/FlashDeals.vue"),
    meta: {
      title: "Applecrumby® - Flash Deals - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Flash Deals Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Flash Deals - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Flash Deals - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/flash_deals/index.html",
        name: "FlashDeals",
      }
    ]
  },
  {
    path: "/contactus",
    name: "Contact_Us",
    component: () => import("../views/ContactUs.vue"),
    meta: {
      title: "Applecrumby® - Contact Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "About Us" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Contact Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Contact Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/contactus/index.html",
        name: "ContactUs",
      }
    ]
  },
  {
    path: "/termsconditions",
    name: "Terms_And_Condition",
    component: () => import("../views/TermsAndCondition.vue"),
    meta: {
      title: "Applecrumby® - Terms And Condition - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Terms And Condition Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Terms And Condition - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Terms And Condition - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/termsconditions/index.html",
        name: "TermsAndCondition",
      }
    ]
  },
  {
    path: "/new_launches",
    name: "New_Launches",
    component: () => import("../views/NewLaunches.vue"),
    meta: {
      title: "Applecrumby® - New Launches - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "New Launches Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - New Launches - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - New Launches - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },
      ],
    },
    children: [
      {
        path: "/new_launches/index.html",
        name: "NewLaunches",
      }
    ]
  },
  {
    path: "/account/my_profile",
    name: "My_Profile",
    component: () => import("../views/MyProfile.vue"),
    meta: {
      title: "Applecrumby® - My Profile - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "My Profile" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - My Profile - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - My Profile - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/account/my_profile/index.html',
        name: "MyProfile",
      }
    ]
  },
  {
    path: "/account/my_wishlist",
    name: "My_Wishlist",
    component: () => import("../views/MyWishlist.vue"),
    meta: {
      title: "Applecrumby® - My Wishlist - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "My Wishlist" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - My Wishlist - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - My Wishlist - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/account/my_wishlist/index.html',
        name: "MyWishlist",
      }
    ]
  },
  {
    path: "/account/my_rewards",
    name: "My_Rewards",
    component: () => import("../views/MyRewards.vue"),
    meta: {
      title: "Applecrumby® - My Rewards - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "My Rewards" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - My Rewards - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - My Rewards - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/account/my_rewards/index.html',
        name: "MyRewards",
      }
    ]
  },
  {
    path: "/account/orders_tracking",
    name: "Orders_Tracking",
    component: () => import("../views/OrdersTracking.vue"),
    meta: {
      title: "Applecrumby® - Order Tracking - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Orders Tracking" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Order Tracking - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Order Tracking - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/account/orders_tracking/index.html',
        name: "OrdersTracking",
      }
    ]
  },
  {
    path: "/account/my_reviews",
    name: "My_Reviews",
    component: () => import("../views/MyReviews.vue"),
    meta: {
      title: "Applecrumby® - My Reviews - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "MyReviews" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - My Reviews - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - My Reviews - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/account/my_reviews/index.html',
        name: "MyReviews",
      }
    ]
  },
  {
    path: "/reward-policy",
    name: "Reward_Policy",
    component: () => import("../views/RewardPolicy.vue"),
    meta: {
      title: "Applecrumby® - Reward Policy - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Reward Policy Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Reward Policy - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Reward Policy - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/reward-policy/index.html',
        name: "RewardPolicy",
      }
    ]
  },
  {
    path: "/policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      title: "Applecrumby® - Privacy Policy - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Privacy Policy Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Privacy Policy - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Privacy Policy - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/policy/index.html',
        name: "Privacy_Policy",
      }
    ]
  },
  {
    path: "/faq",
    name: "FAQ_page",
    component: () => import("../views/FAQ.vue"),
    meta: {
      title: "Applecrumby® - FAQ - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "FAQ Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - FAQ - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - FAQ - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/faq/index.html',
        name: "FAQ",
      }
    ]
  },
  {
    path: "/locate_us",
    name: "Locate_Us",
    component: () => import("../views/LocateUs.vue"),
    meta: {
      title: "Applecrumby® - Locate Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Locate Us Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Locate Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Locate Us - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: '/locate_us/index.html',
        name: "LocateUs",
      }
    ]
  },
  {
    path: "/bloom-this",
    name: "BloomThis",
    component: () => import("../views/BloomThis.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Bloom This" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/bloom-this" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/bloom-this" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/bloom-this" },
      ],
    },
    children: [
      {
        path: '/bloom-this/index.html',
        name: "Bloom_This",
      }
    ]
  },
  {
    path: "/regional_partners/thailand",
    name: "Regional_Partner - Thailand",
    component: () => import("../views/RegionalPageBrief/ThailandLandingPage.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Regional Partner Brief - Thailand" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/regional_partners/thailand/index.html" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/regional_partners/thailand/index.html" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/regional_partners/thailand/index.html" },

      ],
    },
    children: [
      {
        path: '/regional_partners/thailand/index.html',
        name: "Regional Partner - Thailand",
      }
    ]
  },
  {
    path: "/regional_partners/philippines",
    name: "Regional_Partner - Philippines",
    component: () => import("../views/RegionalPageBrief/PhilippinesLandingPage.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Regional Partner Brief - Philippines" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/regional_partners/philippines/index.html" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/regional_partners/philippines/index.html" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/regional_partners/philippines/index.html" },

      ],
    },
    children: [
      {
        path: '/regional_partners/philippines/index.html',
        name: "Regional Partner - Philippines",
      }
    ]
  },
  {
    path: "/regional_partners/singapore",
    name: "Regional_Partner - Singapore",
    component: () => import("../views/RegionalPageBrief/SingaporeLandingPage.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Regional Partner Brief - Singapore" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/regional_partners/singapore/index.html" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/regional_partners/singapore/index.html" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/regional_partners/singapore/index.html" },
      ],
    },
    children: [
      {
        path: '/regional_partners/singapore/index.html',
        name: "Regional Partner - Singapore",
      }
    ]
  },
  {
    path: "/regional_partners/korea",
    name: "Regional_Partner - Korea",
    component: () => import("../views/RegionalPageBrief/KoreaLandingPage.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Regional Partner Brief - Korea" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/regional_partners/korea/index.html" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/regional_partners/korea/index.html" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/regional_partners/korea/index.html" },
      ],
    },
    children: [
      {
        path: '/regional_partners/korea/index.html',
        name: "Regional_Partner - Korea",
      }
    ]
  },
  {
    path: "/regional_partners/vietnam",
    name: "Regional_Partner - Vietnam",
    component: () => import("../views/RegionalPageBrief/VietnamLandingPage.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Regional Partner Brief - Vietnam" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/regional_partners/vietnam/index.html" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/regional_partners/vietnam/index.html" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/regional_partners/vietnam/index.html" },
      ],
    },
    children: [
      {
        path: '/regional_partners/vietnam/index.html',
        name: "Regional Partner - Vietnam",
      }
    ]
  },
  {
    path: "/affiliate/index.html",
    name: "Affiliate",
    component: () => import("../views/AffiliateLandingPage.vue"),
    meta: {
      title: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Affiliate" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/affiliate/index.html" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/regional_partners/vietnam/index.html" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/regional_partners/vietnam/index.html" },

      ],
    },
    props: (route) => ({ name: route.query.name, email: route.query.email, contactno: route.query.contactno }),
  },
  {
    path: "/affiliate",
    redirect: "/affiliate/index.html",
  },
  {
    path: "/affiliates_agreement_form/index.html",
    name: "Affiliates Agreement Form",
    component: () => import("../views/AffiliateAgreementForm.vue"),
    meta: {
      title: "Applecrumby® - Affiliates Agreement Form - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Affiliates Agreement Form" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/affiliates_agreement_form/index.html" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/affiliates_agreement_form/index.html" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Affiliates Agreement Form - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Affiliates Agreement Form - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/affiliates_agreement_form/index.html" },

      ],
    },
  },
  {
    path: "/affiliates_agreement_form",
    redirect: "/affiliates_agreement_form/index.html",
  },
  {
    path: "/sales/:slug",
    name: "SalesPage",
    component: () => import("../views/SalesProduct.vue"),
    meta: {
      title: "Applecrumby® - All Sales - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      params: {
        slug: "",
      },
      metaTags: [
        { name: "description", content: "All Sales Page" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - All Sales - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - All Sales - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },

      ],
    },
    children: [
      {
        path: "/sales/:slug" + "/index.html",
        name: "Sales",
      }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: () => import("../views/ErrorPage.vue"),
    meta: {
      title: "Applecrumby® - Error 404 - Malaysia's Leading Chlorine Free Diapers & Organic Essentials",
      metaTags: [
        { name: "description", content: "Page Not Found" },
        { itemprop: "url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:url", content: "https://www.applecrumbyandfish.com/" },
        { name: "twitter:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { itemprop: "name", content: "Applecrumby® - Page Not Found - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:title", content: "Applecrumby® - Page Not Found - Malaysia's Leading Chlorine Free Diapers & Organic Essentials" },
        { property: "og:description", content: "Applecrumby® offers only premium, safe, natural and organic baby essentials at the best bundle prices & freebies. Free delivery. Refund Assurance. Freebies With Every Order." },
        { property: "og:url", content: "https://www.applecrumbyandfish.com/" },
      ],
    },
  },
];

let routeFinalData = routes.concat(routeExternalData);

const router = createRouter({
  history: createWebHistory(
    process.env.BASE_URL),
  routes: routeFinalData,
})


// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

trackRouter(router);


export default router;