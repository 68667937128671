<template>
  <header>
    <div class="header_main-fixed " :class="this.isHeaderHidden ? 'hidden' : ''" @mouseleave="this.profileIconHover = false">
      <div class="container">
        <div class="header_main-fixed-content">
          <div class="header__top">
            <div class="header__top-column header__top-column--menu">
              <div class="header__top-column--menu-button">
                <!-- class="navbar-toggler" -->
                <button id="header_top-main" class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#header_content" aria-controls="header_content " aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span class="toggler-icon top-bar"></span>
                  <span class="toggler-icon middle-bar"></span>
                  <span class="toggler-icon bottom-bar"></span>
                </button>

                <button id="header_top-main-mobile" class="navbar-toggler collapsed" data-bs-toggle="offcanvas"
                  type="button" :aria-controls="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'"
                  :data-bs-target="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'" aria-expanded="false">
                  <span class="toggler-icon top-bar"></span>
                  <span class="toggler-icon middle-bar"></span>
                  <span class="toggler-icon bottom-bar"></span>
                </button>
              </div>
            </div>
            <div class="header__top-column header__top-column--logo">
              <router-link @click="scrollToTop" to="/"><img alt="logo" src="../assets/images/logo.png" /></router-link>
            </div>
            <div class="header__top-column header__top-column--icons">
              <ul>
                <li>
                  <img alt="search" src="../assets/images/landingpage/search.svg" data-bs-toggle="collapse"
                    data-bs-target="#searchCollapse" />
                </li>
                <li @mouseover="this.profileIconHover = true">
                  <router-link :to="isLoggedIn ? '/account/my_profile/index.html' : '/login/index.html'"><img alt="user"
                      :src="isLoggedIn ? require('../assets/images/icons/user-icon/loginuser.png') : require('../assets/images/icons/user-icon/guest.png')" /></router-link>
                </li>
                <li>
                  <router-link @click="checkout()" to="/checkout/cart/index.html"><b-avatar src=""
                      class="header__top-column--icons-cart" :badge="this.$store.state.badgeCount.toString()" badge-top><img alt="cart"
                        src="../assets/images/landingpage/cart.svg" /></b-avatar></router-link>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="header_region">
            <ul>
              <li><router-link to="/regional_partners/thailand/index.html">Thailand</router-link></li>
              <li><router-link to="/regional_partners/philippines/index.html">Philippines</router-link></li>
              <li><router-link to="/regional_partners/singapore/index.html">Singapore</router-link></li>
              <li><a>Malaysia</a></li>
              <li><router-link to="/regional_partners/korea/index.html">Korea</router-link></li>
              <li><router-link to="/regional_partners/vietnam/index.html">Vietnam</router-link></li>
            </ul>
          </div> -->
          <div class="profile_dropdown" v-if="this.profileIconHover == true">
            <div class="profile_dropdown-list">
              <ul>
                <li v-if="isLoggedIn">
                  <router-link @click="scrollToTop" to="/account/my_profile/index.html">Profile</router-link>
                </li>
                <li v-if="isLoggedIn">
                  <router-link @click="scrollToTop" to="/account/my_rewards/index.html">My Rewards</router-link>
                </li>
                <li v-if="isLoggedIn">
                  <router-link @click="scrollToTop" to="/account/my_wishlist/index.html">Wishlist</router-link>
                </li> 
                <li>
                  <a href="https://www.applecrumbyandfish.com/track/index.html">Track Order</a>
                </li>
                <li>
                  <router-link @click="scrollToTop" to="/checkout/pending/index.html">Upload Bank-In Slip</router-link>
                </li>
              </ul>
            </div>
            <div class="profile_dropdown-action">
              <button class="btn btn-secondary" @click="profileDropdownAction()">{{ !isLoggedIn ? 'Login' : 'Logout'}}</button>
              <p v-if="!isLoggedIn">No account yet? <router-link @click="scrollToTop" to="/signup/index.html">REGISTER HERE.</router-link></p>
            </div>
          </div>
        </div>

      </div>
      <div class="header__menu" @mouseleave="this.profileIconHover = false">
        <div class="container">
          <div class="header__menu_item-navigation">
            <div class="navigation_list-item navigation_list-item-active">
              <div class="navigation_list-item-link">
                <router-link @click="scrollToTop" to="/category/all/index.html">SHOP ALL</router-link>
              </div>
            </div>
            <div class="navigation_list-item">
              <div class="navigation_list-item-link dropdown">
                <router-link @click="scrollToTop" to="/diapers/index.html">Diapers
                  <!-- <b-icon-chevron-down class="navigation-dropdown" /> -->
                </router-link>
                <!-- <div class="dropdown-content">
                                <router-link to="">All</router-link>
                                <router-link to="#">Premium Overnight Diapers</router-link>
                                <router-link to="#">Slimdry Easy Diapers</router-link>
                                <router-link to="#">Limited Edition Designer Diapers</router-link>
                            </div> -->
              </div>
            </div>
            <div class="navigation_list-item">
              <div class="navigation_list-item-link dropdown">
                <router-link @click="scrollToTop" to="/wipes/index.html">Wipes
                  <!-- <b-icon-chevron-down class="navigation-dropdown" /> -->
                </router-link>
                <!-- <div class="dropdown-content">
                                <router-link to="#">All</router-link>
                                <router-link to="#">Premium Honey Wipes</router-link>
                                <router-link to="#">Pure Water Wipes</router-link>
                                <router-link to="#">Fresh Hydrating Wipes</router-link>
                            </div> -->
              </div>
            </div>
            <div class="navigation_list-item">
              <div class="navigation_list-item-link dropdown">
                <router-link @click="scrollToTop" to="/skincare/index.html">Skincare
                  <!-- <b-icon-chevron-down class="navigation-dropdown" /> -->
                </router-link>
                <!-- <div class="dropdown-content">
                                <router-link to="/">All</router-link>
                                <router-link to="#">Pure Newborn Skincare</router-link>
                                <router-link to="#">Premium Skincare</router-link>
                            </div> -->
              </div>
            </div>
            <div class="navigation_list-item">
              <div class="navigation_list-item-link dropdown">
                <router-link @click="scrollToTop" to="/cleaning/index.html">Cleaning
                  <!-- <b-icon-chevron-down class="navigation-dropdown" /> -->
                </router-link>
              </div>
            </div>
            <!--div class="navigation_list-item">
              <div class="navigation_list-item-link dropdown">
                <router-link @click="scrollToTop" to="/oralcare/index.html">Oral Care
                </router-link>
              </div>
            </div-->
            <div class="navigation_list-item">
              <div class="navigation_list-item-link">
                <router-link @click="scrollToTop" to="/new_launches/index.html">New Launches </router-link>
              </div>
            </div>
            <div class="navigation_list-item">
              <div class="dropdown-border">
                <div class="sales-dropdown">
                  <div class="sales-dropdown-navigation"  v-show="index == 0" v-for="(collection, index) in collections" :key="index">
                    <router-link @click="scrollToTop" :to="{ path: '/sales/' + collection.slug + '/index.html' }" v-bind:style="{ 'text-decoration' : 'none' }">
                      <img alt="sales" src="../assets/images/landingpage/sales.svg" />SALES
                    </router-link>
                  </div>
                  <div class="sales-dropdown-content">
                    <template v-for="(collection, index) in collections" :key="index">
                      <router-link @click="scrollToTop" :to="{ path: '/sales/' + collection.slug + '/index.html' }">{{ collection.title
                        }}</router-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="navigation_list-item" v-if='pickupCollection.length > 0'>
              <div class="dropdown-border">
                <div class="sales-dropdown">
                  <div class="sales-dropdown-navigation"  v-show="index == 0" v-for="(collection, index) in pickupCollection" :key="index">
                    <router-link @click="scrollToTop" :to="{ path: '/pickup_deal/' + collection.slug + '/index.html' }" v-bind:style="{ 'text-decoration' : 'none' }">
                      <img alt="pickup_deal" src="../assets/images/landingpage/Pick-Up-Deal.png" v-bind:style="{'width' : '27px' , 'bottom' : '3px'}"/>PICK-UP DEAL
                    </router-link>
                  </div>
                  <div class="sales-dropdown-content">
                    <template v-for="(collection, index) in pickupCollection" :key="index">
                      <router-link @click="scrollToTop" :to="{ path: '/pickup_deal/' + collection.slug + '/index.html' }">{{ collection.title
                        }}</router-link>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="navigation_list-item flash-deals">
              <div class="navigation_list-item-link">
                <router-link @click="scrollToTop" to="/flash_deals/index.html" class="flash-deals"><img alt="flash deal"
                    src="../assets/images/landingpage/flash-deal.svg" /> FLASH DEALS </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="header_content">
        <div class="hd_header">
          <div class="container">
            <div class="header-menu">
              <div class="header-menu-left">
                <ul class="header-menu-left-main">
                  <li>
                    <router-link @click="scrollToTop" to="/new_launches/index.html">
                      <strong>NEW LAUNCHES</strong>
                    </router-link>
                  </li>
                  <li v-show="index == 0" v-for="(collection, index) in collections" :key="index">
                    <router-link @click="scrollToTop" :to="{ path: '/sales/' + collection.slug + '/index.html' }">
                      <strong>SALES</strong>
                    </router-link>
                  </li>
                  <li v-show="index == 0" v-for="(collection, index) in pickupCollection" :key="index">
                    <router-link @click="scrollToTop" :to="{ path: '/pickup_deal/' + collection.slug + '/index.html' }">
                      <strong>PICK-UP DEAL</strong>
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/flash_deals/index.html">
                      <strong>FLASH DEALS</strong>
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/bloom-this/index.html">
                      <strong>Newborn Hampers</strong>
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li><strong>SHOP BY CATEGORY</strong></li>
                  <li>
                    <router-link @click="scrollToTop" to="/diapers/index.html">
                      Diapers
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/wipes/index.html">
                      Wipes
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/skincare/index.html">
                      Skincare
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/cleaning/index.html">
                      Cleaning
                    </router-link>
                  </li>
<!--                   <li>
                    <router-link @click="scrollToTop" to="/oralcare/index.html">
                      Oral Care
                    </router-link>
                  </li> -->
                </ul>
                <ul>
                  <li><strong>HELP CENTRE</strong></li>
                  <li>
                    <router-link @click="scrollToTop" :to="{ name: 'FAQ', hash: '#delivery' }">
                      Delivery
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" :to="{ name: 'FAQ', hash: '#returns' }">
                      Returns
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" :to="{ name: 'FAQ', hash: '#orders' }">
                      Payment
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/reward-policy/index.html">
                      Rewards
                    </router-link>
                  </li>
                </ul>
                <ul>
                  <li><strong>DISCOVER MORE</strong></li>
                  <li>
                    <router-link @click="scrollToTop" to="/aboutus/index.html">
                      About Us
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/contactus/index.html">
                      Contact Us
                    </router-link>
                  </li>
                  <li>
                    <router-link @click="scrollToTop" to="/locate_us/index.html" class="hd_header-column-item">
                      Locate Us
                    </router-link>
                  </li>
                  <li>
                    <a href="https://blog.applecrumbyandfish.com/" target="_blank">
                      Blog
                    </a>
                  </li>
                </ul>
                <ul>
                  <li><strong>REGIONAL PARTNERS</strong></li>
                  <li>
                    <a href="/regional_partners/thailand/index.html" target="_blank">
                    Thailand
                    </a>
                  </li>
                  <li>
                    <a href="/regional_partners/philippines/index.html" target="_blank">
                      Philippines
                    </a>
                  </li>
                  <li>
                    <a href="/regional_partners/singapore/index.html" target="_blank">
                      Singapore
                    </a>
                  </li>
                  <li>
                    <a target="_blank">
                      Malaysia
                    </a>
                  </li>
                  <li>
                    <a href="/regional_partners/korea/index.html" target="_blank">
                      Korea
                    </a>
                  </li>
                  <li>
                    <a href="/regional_partners/vietnam/index.html" target="_blank">
                      Vietnam
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="header_content-mobile">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasHeaderMobile"
          aria-labelledby="offcanvasHeaderMobileLabel">
          <div class="offcanvas-header">
            <div class="header_content-mobile-title">
              <BIconPerson />
              <div class="header_content-mobile-title-info">
                <h5 class="offcanvas-title">Hi, {{ userProfile.firstname }} {{ userProfile.lastname }}!</h5>
                <p>{{ userPoints.point_balance }} Applecrumby® Points</p>
              </div>
            </div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="header_content-mobile-list">
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasMyAccount" data-bs-target="#offcanvasMyAccount">
                <p>My Account</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasShopAll" data-bs-target="#offcanvasShopAll">
                <p data-bs-dismiss="offcanvas">Shop All</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/new_launches/index.html">New Launches</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasSales" data-bs-target="#offcanvasSales" v-if="collections.length > 0">
                <p data-bs-dismiss="offcanvas">Sales</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasPickupSales" data-bs-target="#offcanvasPickupSales" v-if="pickupCollection.length > 0">
                <p data-bs-dismiss="offcanvas">PICK-UP DEAL</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/flash_deals/index.html">Flash Deals</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="https://www.applecrumbyandfish.com/track/index.html">Track Order</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link @click="scrollToTop" to="/checkout/pending/index.html">Upload Bank-In Slip</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/bloom-this/index.html">Newborn Hampers</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/aboutus/index.html">About Us</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/contactus/index.html">Contact Us</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/locate_us/index.html">Locate Us</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="https://blog.applecrumbyandfish.com/" target="_blank">Blog</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasRegionalPartners" data-bs-target="#offcanvasRegionalPartners">
                <p data-bs-dismiss="offcanvas">Regional Partners</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/faq/index.html">FAQ</router-link>
                </p>
              </div>
            </div>
            <div class="header_content-mobile-action">
              <button class="btn btn-secondary btn-log-out" @click="logout($event)">Log Out</button>
            </div>
          </div>
        </div>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMyAccount"
          aria-labelledby="offcanvasMyAccountLabel">
          <div class="offcanvas-header">
            <div class="header_content-mobile-title"></div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="header_content-mobile-list">
              <div class="header_content-mobile-list-item">
                <BIconChevronLeft data-bs-toggle="offcanvas" role="button" aria-controls="offcanvasHeaderMobile"
                  data-bs-target="#offcanvasHeaderMobile" />
                <p>My Account</p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/account/my_profile/index.html">My Profile</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/account/my_wishlist/index.html">My Wishlist</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/account/my_rewards/index.html">My Rewards</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/account/orders_tracking/index.html">Orders & Tracking</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/account/my_reviews/index.html">My Reviews</router-link>
                </p>
              </div>
            </div>
            <div class="header_content-mobile-action">
              <button class="btn btn-secondary btn-log-out" @click="logout($event)">Log Out</button>
            </div>
          </div>
        </div>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSales"
          aria-labelledby="offcanvasSalesLabel" v-if="collections.length > 0">
          <div class="offcanvas-header">
            <div class="header_content-mobile-title"></div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="header_content-mobile-list">
              <div class="header_content-mobile-list-item">
                <BIconChevronLeft data-bs-toggle="offcanvas" role="button"
                  :aria-controls="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'"
                  :data-bs-target="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'" />
                <p>Sales</p>
              </div>
              <div class="header_content-mobile-list-item" v-for="(collection, index) in collections" :key="index">
                <p data-bs-dismiss="offcanvas">
                  <router-link :to="{ path: '/sales/' + collection.slug + '/index.html' }"> {{ collection.title }} </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasRegionalPartners"
          aria-labelledby="offcanvasRegionalPartners">
          <div class="offcanvas-header">
            <div class="header_content-mobile-title"></div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="header_content-mobile-list">
              <div class="header_content-mobile-list-item">
                <BIconChevronLeft data-bs-toggle="offcanvas" role="button"
                  :aria-controls="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'"
                  :data-bs-target="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'" />
                <p>Regional Partners</p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="/regional_partners/thailand/index.html" target="_blank">Thailand</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="/regional_partners/philippines/index.html" target="_blank">Philippines</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="/regional_partners/singapore/index.html" target="_blank">Singapore</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a target="_blank">Malaysia</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="/regional_partners/korea/index.html" target="_blank">Korea</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="/regional_partners/vietnam/index.html" target="_blank">Vietnam</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasPickupSales"
          aria-labelledby="offcanvasPickupSalesLabel" v-if="pickupCollection.length > 0">
          <div class="offcanvas-header">
            <div class="header_content-mobile-title"></div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="header_content-mobile-list">
              <div class="header_content-mobile-list-item">
                <BIconChevronLeft data-bs-toggle="offcanvas" role="button"
                  :aria-controls="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'"
                  :data-bs-target="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'" />
                <p>PICK-UP DEAL</p>
              </div>
              <div class="header_content-mobile-list-item" v-for="(collection, index) in pickupCollection" :key="index">
                <p data-bs-dismiss="offcanvas">
                  <router-link :to="{ path: '/pickup_deal/' + collection.slug + '/index.html' }"> {{ collection.title }} </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasShopAll"
          aria-labelledby="offcanvasShopAllLabel">
          <div class="offcanvas-header">
            <div class="header_content-mobile-title"></div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="header_content-mobile-list">
              <div class="header_content-mobile-list-item">
                <BIconChevronLeft data-bs-toggle="offcanvas" role="button"
                  :aria-controls="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'"
                  :data-bs-target="isLoggedIn ? '#offcanvasHeaderMobile' : '#offcanvasHeaderMobile_Login'" />
                <p>Shop All</p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/category/all/index.html">Shop All</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/diapers/index.html">Diapers</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/wipes/index.html">Wipes</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/skincare/index.html">Skincare</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/cleaning/index.html">Cleaning</router-link>
                </p>
              </div>
<!--               <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/oralcare/index.html">Oral Care</router-link>
                </p>
              </div> -->
            </div>
            <div class="header_content-mobile-action">
              <button class="btn btn-secondary btn-log-out" @click="logout($event)" :class="isLoggedIn ? '' : ' none'">Log
                Out</button>
            </div>
          </div>
        </div>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasHeaderMobile_Login"
          aria-labelledby="offcanvasHeaderMobile_LoginLabel">
          <div class="offcanvas-header">
            <div class="header_content-mobile-title"></div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
              aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="header_content-mobile-list">
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasShopAll" data-bs-target="#offcanvasShopAll">
                <p data-bs-dismiss="offcanvas">Shop All</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/new_launches/index.html">New Launches</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasSales" data-bs-target="#offcanvasSales" v-if="collections.length > 0">
                <p data-bs-dismiss="offcanvas">Sales</p>
                <BIconChevronRight />
              </div>

              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasPickupSales" data-bs-target="#offcanvasPickupSales" v-if="pickupCollection.length > 0">
                <p data-bs-dismiss="offcanvas">PICK-UP DEAL</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/flash_deals/index.html">Flash Deals</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="https://www.applecrumbyandfish.com/track/index.html">Track Order</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link @click="scrollToTop" to="/checkout/pending/index.html">Upload Bank-In Slip</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/bloom-this/index.html">Newborn Hampers</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/aboutus/index.html">About Us</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/contactus/index.html">Contact Us</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/locate_us/index.html">Locate Us</router-link>
                </p>
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <a href="https://blog.applecrumbyandfish.com/" target="_blank">Blog</a>
                </p>
              </div>
              <div class="header_content-mobile-list-item" data-bs-toggle="offcanvas" role="button"
                aria-controls="offcanvasRegionalPartners" data-bs-target="#offcanvasRegionalPartners">
                <p data-bs-dismiss="offcanvas">Regional Partners</p>
                <BIconChevronRight />
              </div>
              <div class="header_content-mobile-list-item">
                <p data-bs-dismiss="offcanvas">
                  <router-link to="/faq/index.html">FAQ</router-link>
                </p>
              </div>
            </div>
            <div class="header_content-mobile-action">
              <router-link to="/login/index.html">
                <button class="btn btn-secondary btn-log-in" data-bs-dismiss="offcanvas">
                  Log In
                </button>
              </router-link>
            </div>
            <div class="header_content-mobile-signup">
              <p data-bs-dismiss="offcanvas">Or <router-link to="/signup/index.html">Sign Up</router-link> if you do not
                already have an account.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="header_main-search collapse" id="searchCollapse">
        <div class="container">
          <div class="header_main-search-container">
            <img class="header_main-search-container-qrscan" v-if="qrscanDisplay" alt="qr scan" @click="openQrModal()" src="../assets/images/icons/qr_code_scan_icon.svg" />
            <img alt="search" @click="redirectMe()" src="../assets/images/landingpage/search.svg"/>
            <input type="text" v-model="search" v-on:keyup.enter="redirectMe()"
              placeholder="Search for our products..." />
            <button class="btn-close" data-bs-toggle="collapse" data-bs-target="#searchCollapse"></button>
          </div>
        </div>
      </div>
    </div>

    <div class="header_main-highlight">
      <div class="header__highlight">
        <div class="container">
          <p>
            Enjoy FREE SHIPPING with purchase RM150 and above!
            <span class="header__highlight-shipping">
              <img src="../assets/images/icons/truck.svg" />
            </span>
          </p>
          <span class="small-font">West MY and Sabah only*</span>
        </div>
      </div>
    </div>
  </header>

  <BarcodeScanner v-if="triggerQRScan == true" @close="closeQrModal()"/>
</template>

<script>
import globals from "@/config/globals.js";
// import { getAPI, getGuestID, getRAPI } from "@/modules/general";
import { logoutAPI } from "@/modules/auth";
import BarcodeScanner from "@/components/BarcodeScanner.vue"

export default {
  data() {
    return {
      isLoggedIn: false,
      search: "",
      collections: [],
      pickupCollection: [],
      userProfile: "",
      userPoints: "",
      profileIconHover: false,
      triggerQRScan: false,
      qrscanDisplay: false,
      lastScrollTop: 0,
      isHeaderHidden: false,
    };
  },
  async mounted() {
    this.isLoggedIn = globals.isLoggedIn;
    if (this.isLoggedIn != false) {
      // await this.getUserProfile();
      // await this.getUserPoint();
      this.userProfile = globals.user;
      this.userPoints = this.$store.state.user_points;
    }
    this.collections = this.$store.state.site_collection;
    this.pickupCollection = this.$store.state.site_pickup_collection;
    window.addEventListener("resize", this.getDimensions);
    this.getDimensions();
    window.zE('webWidget', 'show');
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
    window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    BarcodeScanner
  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > this.lastScrollTop) {
        // Scrolling down
        this.isHeaderHidden = true;
      } else {
        // Scrolling up
        this.isHeaderHidden = false;
      }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    },
    getDimensions() {
      var widthscreen = document.documentElement.clientWidth;
      if ( widthscreen > 767 ) {
        this.qrscanDisplay = false;
      } else {
        this.qrscanDisplay = true;
      }
    },
    openQrModal() {
      this.triggerQRScan = true;
    },
    closeQrModal() {
      this.triggerQRScan = false;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async profileDropdownAction() {
      if(this.isLoggedIn == false) {
        this.$router.push("/login/index.html");
      } else {
        await this.logout();
      }
    },
    checkout() {
      if (this.$route.path == "/checkout/cart") {
        window.location = "/checkout/cart";
      }
      if (this.$route.path == "/checkout/cart/index.html") {
        window.location = "/checkout/cart/index.html";
      }
    },
    redirectMe() {
      if (window.fbq) {
        window.fbq('track', 'Search');
      }
      this.$router.push({
        name: "ProductCategoryAll",
        query: { search: this.search.toLowerCase() },
      });
    },
    async logout() {
      await logoutAPI();
      window.location = "/";
    },
  },
};
</script>
