<template>
	<div class="qrscanner">
		<div class="modal fade" id="qrscan_modal" tabindex="-1" data-bs-keyboard="false" role="dialog"
			aria-labelledby="modalTitleId" aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title" id="modalTitleId">Barcode Scanner</h1>
								<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-content">
						<div>
							<qrcode-stream @detect="onDetect"></qrcode-stream>
							<div v-if="scannedData">
								<p>Scanned QR Code: {{ scannedData }}</p>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Modal } from "bootstrap";
import { QrcodeStream } from 'vue-qrcode-reader'


export default {
	data() {
		return {
			qrscan: null,
			scannedData: null,
			isOpen: false,
		}
	},
	mounted() {
		var $this = this;
		this.qrscan = new Modal(document.getElementById("qrscan_modal"));
		this.qrscan.show();
		document.getElementById("qrscan_modal").addEventListener("hide.bs.modal", function () {
			$this.$emit("close");
		});
	},
	emits: ['close'],
	methods: {
		onDetect(detectedCodes) {
			this.scannedData = detectedCodes;

			if (detectedCodes != '') {
				this.qrscan.hide();
				this.$emit("close");
				window.location = detectedCodes[0].rawValue;
			}
		},
	},
	components: {
		QrcodeStream,

	},
}
</script>

<style lang="scss">
.qrscanner {
	.modal-dialog {
		.modal-content {
			max-width: 500px;
			width: 100%;
		}
	}
}
</style>