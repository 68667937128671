import { createApp } from "vue";
import { createStore } from 'vuex'


import App from "./App.vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { BootstrapIconsPlugin } from "bootstrap-icons-vue";

import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

import vue3GoogleLogin from 'vue3-google-login';
import VueNumberFormat from 'vue-number-format';

import VueSocialSharing from 'vue-social-sharing';
// import VueAnalytics from 'vue3-analytics'
import VueGtag from "vue-gtag-next";
// import { createGtm } from '@gtm-support/vue-gtm';

const store = createStore({
	state() {
		return {
			badgeCount: 0,
			server_status: '',
			homeCategory: '',
			phoneNumber: '',
			verifyType: '',
			site_collection: '',
			site_pickup_collection: '',
			user_points: '',
			is_new: 0
		}
	},
	mutations: {
		updateBadge(state, value) {
			state.badgeCount = value;
		},
		unVerifyPhone(state, phone) {
			state.phoneNumber = phone;
		},
		switchHomeCategory(state, category) {
			state.homeCategory = category;
		},
		toggleServer(state, status) {
			state.server_status = status;
		},
	}
})

const app = createApp(App);

const vue_tel_input_options = {
	'defaultCountry': 'my',
	'inputOptions': {
		maxlength: 14,
	},
	'dropdownOptions': {
		'showSearchBox': true,
		'showDialCodeInSelection': true
	},
	'mode': 'national',
	// 'customValidate': '/^\+\d+$/',
	'styleClasses': 'ac_vuetelinput'
};

	// Use Plugins
	app.use(VueSocialSharing)
		.use(BootstrapVue3)
		.use(VueScrollTo)
		.use(router)
		.use(BootstrapIconsPlugin)
		.use(vSelect)
		.use(VCalendar)
		.use(VueTelInput, vue_tel_input_options)
		.use(VueNumberFormat, { prefix: 'RM ', decimal: '.', thousand: ',' })
		.use(vue3GoogleLogin, {
			clientId: '999990898097-okh3dl272fc1bd7e872uodrrovlr3kf2.apps.googleusercontent.com'
		})
		.use(VueGtag, {
			property: {
				id: "G-25XF7JMC8Q",
			}
		})
		.use(store)
		// .use(
  // createGtm({
  //   id: 'GTM-5Z7PJ48',
  //   vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // }))
		.mount("#app");
