import globals from "@/config/globals.js";
import { getGuestID, postAPI, postRAPI } from "./general";
import { useToast } from 'vue-toast-notification';

const loginAPI = async (username, password, mergeCart = false) => {
    var data = {
        username: username,
        password: password,
    };
    const $toast = useToast();
    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }
    var response = await postAPI('/login', data);
    if (response.code == 'success') {
        globals.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        globals.access_token = response.data.token;
        globals.user = response.data;
    } else {
        var errorMessage = 'Login Failed.';
        // if (response.message) {
        //     errorMessage.message;
        // }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const facebookAPI = async (access_token, user_id, mergeCart = false, cartLogin = false, trackHash) => {
    var data = {
        access_token: access_token,
        user_id: user_id,
    };
    
    const $toast = useToast();
    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }

    var response = await postAPI('/login/checkFacebook', data);
    if (response.code == 'success') {
        globals.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        globals.access_token = response.data.token;
        globals.user = response.data;

        if (cartLogin) {
            window.location = '/checkout/cart/index.html';
        } else if(trackHash == 'order_shipped' || trackHash == 'order_failed'){
            window.location = '/account/orders_tracking/index.html#' + trackHash ;
        } else {
            window.location = '/';
        }
    } else {
        var errorMessage = 'Login Failed.';
        // if (response.message) {
        //     errorMessage.message;
        // }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const googleAPI = async (code, mergeCart = false) => {
    var data = {
        code: code,
    };
    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }
    const $toast = useToast();
    var response = await postAPI('/login/checkGoogle', data);
    if (response.code == 'success') {
        globals.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        globals.access_token = response.data.token;
        globals.user = response.data;
    } else {
        var errorMessage = 'Login Failed.';
        // if (response.message) {
        //     errorMessage.message;
        // }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const registerAPI = async (firstname, lastname, email, password, gender, countrycode, phone, dob, registration_type, mergeCart = false) => {

    let numString = phone;
    numString = numString.replace(/\D/g,'');
    let final_number = parseInt(numString, 10);
    
    var data = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        gender: gender,
        phone: countrycode + final_number,
        dob: dob,
        registration_type: registration_type
    };

    if (mergeCart) {
        data['merge_cart'] = '1';
        data['guest_id'] = getGuestID();
    }

    const $toast = useToast();
    var response = await postAPI('/register', data);
    if (response.code == 'success') {
        globals.isLoggedIn = true;
        localStorage.access_token = response.data.token;
        globals.access_token = response.data.token;
        globals.user = response.data;
    } else {
        var errorMessage = 'Registration Failed.';

        if (response.code == 'email_exist') {
            response.message = 'Email exists.';

        } else if (response.code == 'invalid_email_input') {
            response.message = 'Invalid email address.';

        } else if (response.code == 'invalid_phone') {
            response.message = 'Invalid phone number.';

        } else if (response.code == 'invalid_password') {
            response.message = 'Invalid password.';

        } else if (response.code == 'invalid_firstname_input') {
            response.message = 'Invalid firstname.';

        } else if (response.code == 'invalid_lastname_input') {
            response.message = 'Invalid lastname.';

        } else if (response.code == 'invalid_gender_input') {
            response.message = 'Invalid gender.';

        } else if (response.code == 'invalid_dob_input') {
            response.message = 'Invalid date of birth.';
            
        } else if (response.code == 'phone_exist') {
            response.message = 'Phone number exists.';
            
        }
        
        // if (response.message) {
        //     errorMessage.message;
        // }
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const logoutAPI = async () => {
    await postAPI('/logout');
    const $toast = useToast();
    globals.isLoggedIn = false;
    localStorage.access_token = '';
    globals.access_token = null;
    globals.user = null;
    $toast.open({
        message: 'Logged out.',
        type: 'success',
        position: 'bottom'
    });
}

const verifyLoginAPI = async () => {
    return await postRAPI('/verify', {}, true);
}

const forgotPasswordAPI = async (target, type) => {
    var data = {
        target: target,
        type: type,
    };

    const $toast = useToast();
    var response = await postAPI('/login/forgotPassword', data);
    if (response.code == 'success') {
        if (type == 'email') {
            $toast.open({
                message: 'New password has been sent to your email.',
                type: 'success',
                position: 'bottom'
            });
        } else {
            $toast.open({
                message: 'New password has been sent to your phone.',
                type: 'success',
                position: 'bottom'
            });    
        }
    } else {
        var errorMessage = 'Password reset failed.';
        $toast.open({
            message: errorMessage,
            type: 'error',
            position: 'bottom'
        });
    }
    return response;
}

const verifyaccount = async (target, type, code) => {
    var data = {
        target: target,
        type: type,
        code: code,
    };

    const $toast = useToast();
    var response = await postAPI('/accountverification', data);
    if (response.code == 'success') {
        if (type == 'email') {
            $toast.open({
                message: 'Email has been verified.',
                type: 'success',
                position: 'bottom'
            });
        } else {
            $toast.open({
                message: 'Phone number has been verified.',
                type: 'success',
                position: 'bottom'
            });    
        }
    } else {
        if (type == 'email') {
            $toast.open({
                message: 'Email verification failed.',
                type: 'error',
                position: 'bottom'
            });
        } else {
            $toast.open({
                message: 'Phone verification failed.',
                type: 'error',
                position: 'bottom'
            });
        }
    }
    return response;
}

export { 
    loginAPI,
    registerAPI,
    forgotPasswordAPI,
    verifyLoginAPI,
    logoutAPI,
    verifyaccount,
    googleAPI,
    facebookAPI
};