import { baseApiURL, RbaseApiURL, LambdabaseApiURL } from "@/config/env.js";
import axios from "axios";
import globals from "@/config/globals.js";
import { v4 as uuidv4 } from 'uuid';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

let emergencyStopAll = false;

const getAPI = async (path, data = {}, with_token = false) => {

    if (emergencyStopAll) {
        return {"code":"invalid_token","message":"The last session has expired. Please refresh your browser."};
    }

    var headers = {};
    // Test Authorization
    // headers['Authorization'] = `Bearer ${globals.access_token}`;

    var passed_access_token = false;
    if ((globals.isLoggedIn && globals.access_token && globals.access_token.trim()) || with_token) {
        headers['Authorization'] = `Bearer ${globals.access_token}`;
        passed_access_token = true;
    }
    var response = await axios.get(`${RbaseApiURL}${path}`, {
        params: data,
        headers: headers
    }).catch((response) => response.response);

    if (passed_access_token && response.data.code == 'invalid_token') {
        
        emergencyStopAll = true;

        const $toast = useToast();
        $toast.open({
            message: response.data.message,
            type: 'error',
            position: 'bottom',
            queue: true,
            duration: 5000,
            onDismiss: function() {
                window.location = "/";
            }
        });
    }

    return response.data;
}

const getRAPI = async (path, data = {}, with_token = false) => {

    if (emergencyStopAll) {
        return {"code":"invalid_token","message":"The last session has expired. Please refresh your browser."};
    }

    var headers = {};
    // Test Authorization
    // headers['Authorization'] = `Bearer ${globals.access_token}`;

    var passed_access_token = false;
    if ((globals.isLoggedIn && globals.access_token && globals.access_token.trim()) || with_token) {
        headers['Authorization'] = `Bearer ${globals.access_token}`;
        passed_access_token = true;
    }
    var response = await axios.get(`${LambdabaseApiURL}${path}`, {
        params: data,
        headers: headers
    }).catch((response) => response.response);

    if (passed_access_token && response.data.code == 'invalid_token') {
        
        emergencyStopAll = true;

        const $toast = useToast();
        $toast.open({
            message: response.data.message,
            type: 'error',
            position: 'bottom',
            queue: true,
            duration: 5000,
            onDismiss: function() {
                window.location = "/";
            }
        });
    }

    return response.data;
}

const postAPI = async (path, data = {}, with_token = false) => {
    var headers = {};

    if (emergencyStopAll) {
        return {"code":"invalid_token","message":"The last session has expired. Please refresh your browser."};
    }

    var passed_access_token = false;
    if ((globals.isLoggedIn && globals.access_token && globals.access_token.trim()) || with_token) {
        headers['Authorization'] = `Bearer ${globals.access_token}`;
        passed_access_token = true;
    }
    var response = await axios({
        method: 'post',
        url: `${baseApiURL}${path}`,
        headers: headers,
        params: data,
    }).catch((response) => response.response);

    if (passed_access_token && response.data.code == 'invalid_token') {
        
        emergencyStopAll = true;

        const $toast = useToast();
        $toast.open({
            message: response.data.message,
            type: 'error',
            position: 'bottom',
            queue: true,
            duration: 5000,
            onDismiss: function() {
                window.location = "/";
            }
        });
    }

    return response.data;
}

const postRAPI = async (path, data = {}, with_token = false) => {
    var headers = {};

    if (emergencyStopAll) {
        return {"code":"invalid_token","message":"The last session has expired. Please refresh your browser."};
    }

    var passed_access_token = false;
    if ((globals.isLoggedIn && globals.access_token && globals.access_token.trim()) || with_token) {
        headers['Authorization'] = `Bearer ${globals.access_token}`;
        passed_access_token = true;
    }
    var response = await axios({
        method: 'post',
        url: `${RbaseApiURL}${path}`,
        headers: headers,
        params: data,
    }).catch((response) => response.response);

    if (passed_access_token && response.data.code == 'invalid_token') {
        
        emergencyStopAll = true;

        const $toast = useToast();
        $toast.open({
            message: response.data.message,
            type: 'error',
            position: 'bottom',
            queue: true,
            duration: 5000,
            onDismiss: function() {
                window.location = "/";
            }
        });
    }

    return response.data;
}

const uploadAPI = async (path, data = {}) => {
    var headers = {
        'Content-Type': 'multipart/form-data'
    };
    if ((globals.isLoggedIn && globals.access_token && globals.access_token.trim())) {
        headers['Authorization'] = `Bearer ${globals.access_token}`;
    }
    var response = await axios.post(`${baseApiURL}${path}`, data, {
        headers: headers
    }).catch((response) => response.response);

    return response.data;
}

const getGuestID = () => {
    if (!localStorage.guest_id) {
        localStorage.guest_id = uuidv4();
    }
    return localStorage.guest_id;
}

export { 
    getAPI,
    getRAPI,
    postAPI,
    postRAPI,
    uploadAPI,
    getGuestID
};