// const baseApiURL = "https://staging-theapi.applecrumbyandfish.com";
// const RbaseApiURL = "https://staging-theapi.applecrumbyandfish.com";

// const baseApiURL = "https://staging-api.applecrumbyandfish.com";
// const RbaseApiURL = "https://8ywchvtwyi.execute-api.ap-southeast-1.amazonaws.com";

const baseApiURL = "https://theapi.applecrumbyandfish.com";
const RbaseApiURL = "https://theapi-r.applecrumbyandfish.com";
const LambdabaseApiURL = "https://theapi-r.applecrumbyandfish.com";

const facebookAppId = "574731810023944";
const googleClientId = "999990898097-okh3dl272fc1bd7e872uodrrovlr3kf2.apps.googleusercontent.com";

var variantTypes = {
    'type': 'Type',
    'size': 'Size'
};

export { baseApiURL, RbaseApiURL, LambdabaseApiURL, variantTypes, facebookAppId, googleClientId };
